import { template as template_14dbd7ddcb444771a2cb153830f89070 } from "@ember/template-compiler";
const FKLabel = template_14dbd7ddcb444771a2cb153830f89070(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
