import { template as template_bc65b909fb274651b768d90a0128a2fc } from "@ember/template-compiler";
const WelcomeHeader = template_bc65b909fb274651b768d90a0128a2fc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
