import { template as template_d482c4f5cb3c44b6bdce28ed156f26f5 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_d482c4f5cb3c44b6bdce28ed156f26f5(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
